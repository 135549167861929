import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import constants from "./config/constants";
import axios from "axios";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

function MapComponent() {
  const [tagData, setTagData] = useState([]);
  const [selectedTag, setSelectedTag] = useState("124353673");
  //  const [selectedLBTag, setSelectedLBTag] = useState("");
  const [mapCenter, setMapCenter] = useState({ lat: 33.1749, lng: -86.7705 });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD-XgMXEcxxhOGZ1nNhxtDTGLj9UeVrV4c",
    libraries,
  });
  const [lightBugSession, setLightBugSession] = useState("");
  const [lightBugDevices, setLightBugDevices] = useState([]);

  useEffect(() => {
    fetchLocations(selectedTag); // Initial fetch

    const interval = setInterval(() => {
      fetchLocations(selectedTag); // Fetch every 1 minute
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [selectedTag, isLoaded]);

  useEffect(() => {
    getLoginSession();
  }, []);

  const getLoginSession = async () => {
    try {
      let response = await fetch(`${constants.baseLightBugURL}lb_login`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const userID = await response.json();
      setLightBugSession(userID);

      const deviceResponse = await axios.post(
        `${constants.baseLightBugURL}lb_devices`,
        {
          userId: userID,
        }
      );
      const devices = deviceResponse.data;
      console.log("LB devices list");
      console.log(devices);
      setLightBugDevices(devices);
      return;
    } catch (error) {
      console.error("Error fetching locations:", error);
      alert("Error fetching locations. Please try again later.");
    }
  };

  const fetchLocations = async (tagId) => {
    if (tagId.startsWith("LB")) {
      try {
        const LBdeviceID = tagId.substring(2);
        const response = await axios.post(
          `${constants.baseLightBugURL}lb_points`,
          {
            deviceId: LBdeviceID,
          }
        );

        const data = await response.data;
        if (data.length > 0) {
          setTagData([data[0]]);
          console.log(data[0], data[0].location.lat, data[0].location.lng);
          setMapCenter({
            lat: parseFloat(data[0].location.lat),
            lng: parseFloat(data[0].location.lng),
          });
        } else {
          alert("No locations found for the selected tag.");
          setTagData([]);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        alert("Error fetching locations. Please try again later.");
        getLoginSession();
      }
    } else {
      try {
        const response = await fetch(
          "https://g86fjl4gxi.execute-api.us-east-1.amazonaws.com/ua/get_smart_tag",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ tagId: tagId }),
          }
        );

        const data = await response.json();

        if (data.length > 0) {
          setTagData(data);
          setMapCenter({
            lat: parseFloat(data[0].lat),
            lng: parseFloat(data[0].lon),
          });
        } else {
          alert("No locations found for the selected tag.");
          setTagData([]);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        alert("Error fetching locations. Please try again later.");
      }
    }
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };

  // const handleLBTagChange = (e) => {
  //   setSelectedLBTag(e.target.value);
  // };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const customMarkerIcon = {
    url: require("./samsung_icon.png"),
    scaledSize: new window.google.maps.Size(55, 35),
  };

  function formatTimestamp(timestamp) {
    timestamp = timestamp.replace(/[-:a-zA-Z]/g, "");
    const date = new Date(
      Date.UTC(
        parseInt(timestamp.substring(0, 4)),
        parseInt(timestamp.substring(4, 6)) - 1, // Month is 0-indexed in JavaScript
        parseInt(timestamp.substring(6, 8)),
        parseInt(timestamp.substring(8, 10)),
        parseInt(timestamp.substring(10, 12)),
        parseInt(timestamp.substring(12, 14))
      )
    );
    const output = date.toLocaleString("en-US", {
      timeZone: "America/Chicago",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return output;
  }

  return (
    <div>
      <div className="tag-list">
        <img className="map-logo" src={require("./tabicon.png")} />
        <h2>Select Asset</h2>
        <select id="tagSelect" onChange={handleTagChange} value={selectedTag}>
          <option value="124353673">BAT2762</option>
          <option value="124368127">E04942</option>
          <option value="124368126">E01052</option>
          <option value="124358783">E04825</option>
          <option value="124358784">E00960</option>
          <option value="121968849">E01313</option>
          <option value="121968850">E01312</option>
          {/* </select>  <select id="LBtagSelect" onChange={handleLBTagChange} value={selectedLBTag}> */}
          {lightBugDevices != null && lightBugDevices.length > 0
            ? lightBugDevices?.map((device) => {
                const deviceId = "LB" + device.id;
                return (
                  <option key={deviceId} value={deviceId}>
                    BAT2672GPS
                  </option>
                );
              })
            : null}
        </select>
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={18}
        center={mapCenter}
        options={{
          mapTypeId: "satellite", // Set default map type to satellite
        }}
      >
        {tagData.map((location, index) => (
          <Marker
            key={index}
            position={{
              lat: parseFloat(mapCenter.lat),
              lng: parseFloat(mapCenter.lng),
            }}
            icon={customMarkerIcon} // Use customMarkerIcon
          >
            <InfoWindow
              position={{
                lat: parseFloat(mapCenter.lat) + 0.0001, // Adjust the latitudinal position slightly
                lng: parseFloat(mapCenter.lng),
              }}
            >
              <div>
                {formatTimestamp(
                  location.oprn_crt_date
                    ? location.oprn_crt_date
                    : location.timestamp
                )}
              </div>
            </InfoWindow>
          </Marker>
        ))}
      </GoogleMap>
    </div>
  );
}

export default MapComponent;
